import React from 'react';

import {
  Col,
} from "react-bootstrap";

const EducationCard = ({ data }) => {
  return (
    <Col lg="6">
      <div className="pb-5 text-center">
        {/* <img className=" bg-white mb-3" src={data.companylogo} alt="" /> */}
        <p className="lead">
          <div className='fw-bold'>{data.organisation}</div>
          <div>{data.title}</div>
          <em className='fw-lighter'>{data.date}</em>
        </p>
      </div>
    </Col>
  );
}

export default EducationCard;